import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {getFormattedDate} from '../../utils/util';
import DayCard from './DayCard';
import {dayWiseScheduleArray} from '../../utils/util';
import location from '../../assets/Location pin.png';

const DayViewLayout = (props) => {
	const navigate = useNavigate();
	const {
		indexval = 0, 
		onHandleItemClick,
		onHandleCTAClick,
		serviceList = [], 
		currentDate = "", 
		activeTab = -1, 
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false,
		startDate = new Date(),
	} = props;
	
	const date_wise_array = dayWiseScheduleArray(serviceList, getFormattedDate(currentDate));

	const onHandleEventBookClick = (eachCls) => {
		if (activeTab === 0) {
			navigate(`/events-classes/service-details/${eachCls?.serviceId}`);
		} else if(activeTab === 1) {
			//
		} else {
			navigate(`/events-classes/service-details/${eachCls?.serviceId}`);
		}
	}

	return (
		<div className="my-2">
			{date_wise_array.length === 0 && <div className="h-100 no-class-container py-3 px-3 text-center d-flex align-items-center justify-content-center">
				
			</div>}
			{date_wise_array.length !== 0 && date_wise_array.map((eachCls, indexVal) => 
				<DayCard 
					item = {eachCls} 
					onHandleCTAClick = {onHandleCTAClick} 
					onHandleEventBookClick = {onHandleEventBookClick} 
					activeTab = {activeTab}
					isCTADisabled = {isCTADisabled}
					CTALabel = {CTALabel}
				/>
			)}
		</div>

	)
}

export default DayViewLayout;