import {getTicketPrice, getTicketServiceFee, pluralize} from '../../utils/util';

const PurchaseView = (props) => {

	const {purchaseDetails = {}, statusMsg = "", retry} = props;

	const {
		purchaseAdditionalDetails = {},
		paymentDetails = {},
		serviceId = "",
		purchaseId = "",
		purchaseType = ""
	} = purchaseDetails || {};

	const {
		bookingList = [],
		
		serviceName = "",
		serviceCategory = "",
		mode = "Online",
		serviceLocation = "",
		pricingType = "",
		subscriptionPriceCurrency = "",
		subscriptionPrice = 0,
		subscriptionName = "",
		subscriptionPaymentPlan = "",
		subscriptionPaymentDuration = "Monthly"
	} = purchaseAdditionalDetails || {};

	const handleClose = () => {

	}

	//console.log("PurchaseView --->", purchaseDetails);

	return (
		<div className="container dialog_padding">
      		<div className="row w-100">
      			<div className="col-lg-8 m-auto w-100">
      				<div className="confirmation_container align-items-center m-auto">
      					<div className="card_item m-auto w-100 ">
      						{serviceId !== "" && <div className="card_item_content m-auto">
      						    <h5 className="heading-5 color-primary-50">
                                    {serviceName} | {serviceCategory} {mode === "physical" && <span>| {serviceLocation?.city}</span>}
                                </h5>

                                {purchaseType === "TICKET" && mode !== "physical" && <h5 className="heading-5 color-primary-50">{mode} Ticket</h5>}
      							<hr />
      						</div>
      						}

      						{!serviceId && 	pricingType === "Subscription" && <>
      							<div className="d-flex justify-content-between align-items-center py-2 w-100">
					               <div className="body-B color-primary-50 text-start">Subscription</div>
					               <div className="body-B color-primary-50 text-end">{subscriptionName}</div>
								</div>

								<div className="d-flex justify-content-between align-items-center py-2 w-100 ">
									<div className="body-B color-primary-50 text-start">Subscription amount</div>
					               <div className="body-R color-primary-50 text-end">{subscriptionPrice} {subscriptionPriceCurrency} / {subscriptionPaymentDuration}</div>
					            </div>
                            <hr />
                            </>
                        	}

      						{serviceId && bookingList && bookingList.map((eachBook, index) => 
                                <>
								{eachBook.pricingType === "ticket" &&
								    <>
									    {eachBook.tickets.ticketList.map((item, idx) => {
									        return(
									          	<div className="d-flex justify-content-between py-2 w-100">
									               	<div className="body-B color-primary-50">{eachBook?.tickets?.ticketName} x {item?.quantity}</div>
									               	<div className="body-R color-primary-50">{getTicketPrice(item) + getTicketServiceFee(item)} EUR</div>
									          		
									          	</div> 
									        )   
									    }   
									    )}
								    </>
								}

								{eachBook.pricingType === "package" && <>
									    <div className="d-flex justify-content-between py-2 w-100">
									        <div className="body-R color-primary-50">{eachBook?.packageName}  x  {eachBook?.quantity}</div>
									        <div className="body-R color-primary-50">{(getTicketPrice(eachBook) +  getTicketServiceFee(eachBook))} EUR</div>
									    </div>
									    <div className="py-2 w-100 body-R color-primary-50">Package - {eachBook?.noOfSessions} {pluralize(eachBook?.noOfSessions, "Session")} for {eachBook?.packageValidityDuration} {pluralize(eachBook?.packageValidityDuration, "month")}</div>
									</>
								}

								{eachBook.pricingType === "plan" && <>
									    <div className="d-flex justify-content-between py-2 w-100">
									          <div className="body-R color-primary-50">{eachBook?.planName}  x  {eachBook?.quantity}</div>
									          <div className="body-R color-primary-50">{paymentDetails?.amount?.value} EUR</div>
									    </div>
									</>
								}
                                </>
                            )}
                            <hr />
      					</div>

      					{serviceId && statusMsg === "Success" && <div>
      						<h5 className="heading-5 color-primary-50 text-center">Yay! You've successfully made a purchase!</h5>
      						<div className="body-R color-primary-50 text-center">The ticket has been sent to your email.</div>
      					</div>}

      					{statusMsg === "Pending" && <div>
      						<h5 className="heading-5 color-primary-50 text-center">Your Card Authentication is currently Pending for approval!</h5>
      					</div>}

      					{statusMsg === "Declined" && <div>
      						<h5 className="heading-5 color-primary-50 text-center">Sorry! Your Card Authentication has declined!</h5>
      					</div>}

      					{/*statusMsg === "Success" && bookingList && bookingList[0] && bookingList[0]?.pricingType === "package" && 
                            <div className="responsive_flex w-100 justify-content-center align-items-center">
        						<div className="body-R color-primary-50 my-2">Want to schedule your session? </div>
                                <div className="px-4">	
            					</div>
            				</div>
                        */}

                        {/*statusMsg === "Success" && bookingList && bookingList[0] && bookingList[0]?.pricingType === "ticket" &&  
	                        <div>
	                            <button className="primary_btn capitalize" onClick={handleClose}>Done</button>
	                        </div>
                        */}

                        {statusMsg !== "Success" && <div>
	                        <button className="primary_btn capitalize" onClick={retry}>Retry</button>
	                    </div>
	                    }

      				</div>
      			</div>
      		</div>
      	</div>

	)
}

export default PurchaseView;