import {useState, useEffect} from 'react';
import {getLocationLink, getTimeFromEpoc, isScheduleExpired} from '../../utils/util';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import location_icon from '../../assets/Location pin.png';

const DayCard = (props) => {
	
	const {
		item = {}, 
		onHandleItemClick, 
		onHandleCTAClick, 
		activeTab = "-1",
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false
	} = props;

	const {
		scheduleMode = "",
		serviceType = "",
		scheduleStartTime = "",
		scheduleEndTime = ""
	} = item || {};
	
	const {
        scheduleId = "",
        serviceName = "",
        serviceId = "",
        serviceDescription = "",
        day = "",
        serviceCategoryDetails = {},
        startTime = "",
        endTime = "",
        service_date = "",
        serviceMeetingLink = "",
        serviceLocation = {},
        duration = "",
        booking_details = [],
        userDetails = {},
        serviceTimezone = ""
    } = item?.serviceDetails || {};

	const [isExpanded, setExpanded] = useState(false);
	const [isExpired, setExpired] = useState(false);

	useEffect(() => {
    	const isExp = isScheduleExpired(scheduleEndTime)
      	setExpired(isExp);
	}, []);

	return (
		<>
			<div className="desktop_view my-3">
				<div className="rounded-4 dayview_container py-3 px-5">
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column justify-content-between">
							{serviceType !== "PRIVATE" && <div className="caption-2 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
							{serviceType === "PRIVATE" && <div className="caption-2 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}
							<div>
								<div className="caption-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
								
							</div>
							<div>
								<button className="ax-button caption-2 color-primary-50" onClick={() => setExpanded(!isExpanded)}> {isExpanded ? "-" : "+"} info</button>
							</div>
						</div>

						<div className="d-flex flex-column justify-content-between">
							<h5 className="caption-2 color-primary-50">{serviceName}</h5>
							
							<div className="d-flex align-items-end">
								{scheduleMode === "physical" && <a href={getLocationLink(serviceLocation)} target="_blank">
									<div className="py-2 caption-2 color-primary-50 d-flex">
										<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '7px'}} alt = "" />
										<h6 className="p-2 caption-2 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
									</div>
								</a>}
								{scheduleMode === "online" && <div className="py-2 caption-2 color-primary-50 d-flex">
									<h6 className="p-2 caption-2 color-primary-50">Online</h6>
								</div>}
							</div>
						</div>

						<div className="d-flex justify-content-center">
							{isCTAShow && <button className="capitalize secondary_btn" onClick={() => onHandleCTAClick(item)} disabled = {isExpired || isCTADisabled || item?.isDisabled === "true"}>{CTALabel}</button>}
						</div>
					</div>

					{isExpanded && <div className="py-2 color-primary-50">
						{serviceDescription}
					</div>
					}
				</div>
			</div>
			
			<div className="dayview_container rounded-4 mobile_view my-3">
				<div className="padding">
					<div className="d-flex justify-content-between align-items-center">
						<div className="subtitle-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
						<div className="subtitle-3 color-primary-50">
							<button className="ax-button" onClick={() => setExpanded(!isExpanded)} disabled = {isCTADisabled || item?.isDisabled === "true"}> {isExpanded ? "-" : "+"} info</button>
						</div>
					</div>

					<div>
						<h4 className="heading-4 color-primary-50">{serviceName}</h4>
					</div>

					<div className="py-2 d-flex justify-content-between align-items-center">
						<div className="subtitle-3 color-primary-50">{startTime} - {endTime}</div>
						<div className="caption-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
								
						<div className="d-flex align-items-end">
							{scheduleMode === "physical" && <a href={getLocationLink(serviceLocation)} target="_blank">
								<div className="py-2 caption-2 color-primary-50 d-flex">
									<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '7px'}} alt = "" />
									<h6 className="p-2 caption-2 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
								</div>
							</a>}
							{scheduleMode === "online" && <div className="py-2 caption-2 color-primary-50 d-flex">
								<h6 className="p-2 caption-2 color-primary-50">Online</h6>
							</div>}
						</div>
					</div>
					
				</div>
				<div className="d-flex justify-content-center pt-3">
					<button className="capitalize book_btn" onClick={() => onHandleCTAClick(item)} disabled = {isCTADisabled || item?.isDisabled === "true"}>{CTALabel}</button>
				</div>
			</div>
		</>
	)
}

export default DayCard;